import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

//Firebase configuration to connect to real time database
const firebaseConfig = {
    apiKey: "AIzaSyAeqVz7ZH4lOG51y0T3LC470i3fYPvNCzk",
    authDomain: "juice-93e2a.firebaseapp.com",
    databaseURL: "https://juice-93e2a-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "juice-93e2a",
    storageBucket: "juice-93e2a.appspot.com",
    messagingSenderId: "108130416518",
    appId: "1:108130416518:web:f7fe19f916b555430a9e07"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export {db}