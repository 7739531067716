import React, { Component } from 'react'
import { Feed } from 'semantic-ui-react'
import FeedItem from './FeedItem';

//Renders the feed of check ins within a day
export default class FeedDay extends Component {
    render() {
        const data = this.props.feed;
        const feedComponents = data.map((item) =>
            <FeedItem data={item} key={item.key}></FeedItem>
        )

        return (
            <Feed>
                {feedComponents}
            </Feed>
        )
    }
}
