import { Container, Divider, Header, Icon, Menu } from 'semantic-ui-react';
import './App.css';
import Daily from './Views/Daily';

function App() {

  return (
    <Container>
      <Menu text>
        <Header as='h2'>
          <Icon circular inverted name='coffee' color='teal'/>
          <Header.Content>
            daily
            <Header.Subheader>day by day</Header.Subheader>
          </Header.Content>
        </Header>
      </Menu>
      <Divider></Divider>
      <Container>
        <Daily></Daily>
      </Container>
    </Container>
  );
}

export default App;
