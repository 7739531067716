import React, { Component } from 'react'
import { Container, Input, Label } from 'semantic-ui-react'
import { db } from '../Database/firebase'
import { collection, addDoc, Timestamp } from 'firebase/firestore'

//Input Field for Check In
export default class CheckInField extends Component {
    constructor(props) {
        super(props);
        this.state = { data: '', showError: false }
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    //Does a REGEX check against check in input for format: <number> [hr | hrs] #<tag> <activities>
    isCheckInFormatValid(data) {
        if (/^([0-9]+\.?[0-9]*)\s(hr|hrs)\s#(\S)+\s(.)+$/.test(data)) {
            return true;
        }
        else {
            return false;
        }
    }

    //Handle enter key down
    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.createCheckIn();
        }
    }

    //React requires binding change event for input fields
    handleChange(event) {
        this.setState({ data: event.target.value });
    }

    //Push new check in to firebase
    async createCheckIn() {
        if (this.isCheckInFormatValid(this.state.data)) {
            this.setState({ showError: false });
            let input = this.state.data.split(" ");
            try {
                await addDoc(collection(db, 'daily'), {
                    postDateTime: Timestamp.now(),
                    hours: input[0],
                    tag: input[2].substring(1),
                    description: input.slice(3).join(" ")
                }).then((docRef) => {
                    this.setState({
                        data: ''
                    });
                })
            } catch (err) {
                console.error("Error adding document: ", err);
            }
        }
        else {
            this.setState({ showError: true });
        }
    }
    render() {
        return (
            <Container>
                <Input
                    fluid
                    value={this.state.data}
                    icon='compose'
                    iconPosition='left'
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    action={{
                        content: 'Check In',
                        onClick: () => this.createCheckIn(),
                    }}
                    placeholder='<number> [hr | hrs] #<tag> <activities>'>
                </Input>
                <Label basic color='red' pointing style={{ visibility: this.state.showError ? 'visible' : 'hidden' }}>
                    Incorrect format.
                </Label>
            </Container>
        )
    }
}
