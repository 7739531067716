import React, { Component } from 'react'
import { Feed, Button, Label, Grid, GridColumn, Confirm } from 'semantic-ui-react'
import { db } from '../Database/firebase'
import { doc, deleteDoc } from 'firebase/firestore'

//Renders the check in details 
export default class FeedItem extends Component {
    constructor(props) {
        super(props);
        this.state = { showDelete: false, openConfirm: false }
    }

    //Event handlers to display delete button
    onMouseOver = (e) => this.setState({ showDelete: true })
    onMouseOut = (e) => this.setState({ showDelete: false })

    //Event handlers to display confirm delete
    showConfirm = () => this.setState({ openConfirm: true })
    handleCancel = () => {this.setState({ openConfirm: false, showDelete: false })}
    handleConfirm = () => {
        this.setState({ openConfirm: false, showDelete: false })
        this.deleteItem();
    }

    //Calls on firebase to delete item
    async deleteItem() {
        const taskDocRef = doc(db, 'daily', this.props.data.key)
        try {
            await deleteDoc(taskDocRef)
        } catch (err) {
            console.log(err)
        }
    }

    render() {
        const item = this.props.data;

        return (
            <Feed.Event key={item.key}
                onMouseEnter={this.onMouseOver.bind(this)}
                onMouseLeave={this.onMouseOut.bind(this)}>
                <Feed.Label style={{ padding: '15px 0px' }}>
                    <Label circular size='big' color='teal'>
                        {item.hours}
                    </Label>
                </Feed.Label>
                <Feed.Content>
                    <Grid>
                        <GridColumn width="13">
                            <Feed.Summary>
                                {item.description}
                                <Feed.Date>
                                    #{item.tag}
                                </Feed.Date>
                            </Feed.Summary>
                            <Feed.Meta>
                                {item.postDateTime.toLocaleString()}
                            </Feed.Meta>
                        </GridColumn>
                        <GridColumn width="3">
                            <Button circular
                                inverted
                                color='red'
                                icon='delete'
                                onClick={this.showConfirm}
                                style={{ visibility: this.state.showDelete ? 'visible' : 'hidden' }} />

                            <Confirm
                                content='Are sure you want to delete this check in?'
                                open={this.state.openConfirm}
                                onCancel={this.handleCancel}
                                onConfirm={this.handleConfirm}
                            />
                        </GridColumn>
                    </Grid>
                </Feed.Content>
            </Feed.Event>
        )
    }
}
