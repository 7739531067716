import React, { Component } from 'react'
import { Container, Grid, GridColumn } from 'semantic-ui-react'
import FeedView from './FeedView';
import AddCheckIn from './AddCheckIn';
import ChartView from './ChartView';
import { collection, query, orderBy, onSnapshot } from "firebase/firestore"
import { db } from '../Database/firebase'

//Renders the entire content of the website, including Feed and Chart
export default class Daily extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [], dataGrouped: [] }
    }

    componentDidMount() {
        this.pullFireBaseData();
    }

    //Transforming data to readily be usable by feed, grouping by date
    transformFeedData(data) {
        var dataGroupedTemp = data.reduce((acc, item) => {
            let date = new Date(item.postDateTime);
            let key = `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {})
        this.setState({ dataGrouped: dataGroupedTemp });
    }

    //Pulls check in data from firebase
    pullFireBaseData() {
        const dailyData = query(collection(db, 'daily'), orderBy('postDateTime'))
        onSnapshot(dailyData, (snapshot) => {
            const dataTemp = [];
            snapshot.forEach((doc) => {
                const { postDateTime, hours, tag, description } = doc.data();
                dataTemp.push({
                    key: doc.id,
                    doc,
                    postDateTime: postDateTime.toDate(),
                    hours,
                    tag,
                    description
                });
            });
            this.setState({ data: dataTemp.reverse() });
            this.transformFeedData(dataTemp);
        })
    }

    render() {
        return (
            <Container>
                <Grid>
                    <GridColumn width={6}>
                        <AddCheckIn></AddCheckIn>
                        <FeedView data={this.state.dataGrouped}></FeedView>
                    </GridColumn>
                    <GridColumn width={10}>
                        <ChartView data={this.state.data}></ChartView>
                    </GridColumn>
                </Grid>
            </Container>
        )
    }
}