import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import FeedDay from './FeedDay';

//Renders the entire feed of check ins from different days 
export default class FeedView extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 }
    }

    //Handles changing of day to view
    handleClick = (e, props) => {
        const newIndex = this.state.activeIndex === props.index ? -1 : props.index
        this.setState({ activeIndex: newIndex })
    }

    render() {
        const data = this.props.data;
        const feed = Object.keys(data).map((item, index) =>
            <div key={index}>
                <Accordion.Title
                    active={this.state.activeIndex === index}
                    index={index}
                    onClick={this.handleClick}>
                    <Icon name='dropdown' />
                    {item}
                </Accordion.Title>
                <Accordion.Content active={this.state.activeIndex === index}>
                    <FeedDay feed={data[item]}></FeedDay>
                </Accordion.Content>
            </div>
        );

        return (
            <Accordion fluid styled>
                {feed}
            </Accordion>
        )
    }
}