import React, { Component } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Segment } from 'semantic-ui-react';

ChartJS.register(ArcElement, Tooltip, Legend);

//Renders Pie Chart for Check Ins grouped by Tag
export default class ChartView extends Component {
    render() {
        //Format data into key (tag) value (hours) format
        const dataFormatted = this.props.data.reduce((acc, item) => {
            if (!acc[item.tag]) {
                acc[item.tag] = item.hours;
            }
            else {
                acc[item.tag] += item.hours;
            }
            return acc;
        }, {})

        const data = {
            labels: Object.keys(dataFormatted),
            datasets: [
                {
                    label: 'Time Consumed by Tag',
                    data: Object.values(dataFormatted),
                    borderWidth: 1,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                },
            ]
        }

        return (
            <Segment>
                <Pie data={data} />
            </Segment>
        )
    }
}
